import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

/**
 * AddressInfoCard Component
 *
 * This component displays information about a workshop's address, contact details,
 * and provides an option to save the workshop. It calculates and displays a discount
 * percentage based on the market-related price and the workshop's pricing.
 *
 * @component
 * @param {Object} props - Props for the component.
 * @param {Object} props.workshop - Workshop details object.
 * @returns {JSX.Element} AddressInfoCard component JSX element.
 */
export const ConditionalInfoCard: React.FC<any> = ({
  workshop,
  discountPercentage,
}) => {
  const [workdiscountPercentage, setWorkDiscountPercentage] = useState<
    number | null
  >(null);
  const [areaDifference, setAreaDifference] = useState<number | null>(null);

  // Fetch the discount percentage from local storage when the component mounts and this includes area code discount difference.
  useEffect(() => {
    const savedDiscount = localStorage.getItem("discount-percentage");
    const savedAreaDifference = localStorage.getItem("area-difference")
    // console.log("The saved area code: ", savedAreaDifference)

    if (savedDiscount !== null) {
      const parsedDiscount = parseFloat(savedDiscount);
      if (!isNaN(parsedDiscount)) {
        const formatDiscount = parseFloat(parsedDiscount.toFixed(2));
        setWorkDiscountPercentage(formatDiscount);
      } else {
        console.warn(
          "Invalid discount-percentage found in localStorage:",
          savedDiscount
        );
      }
    }

    if (savedAreaDifference !== null) {
      const parseAreaDifference = parseFloat(savedAreaDifference);
      if (!isNaN(parseAreaDifference)) {
        setAreaDifference(parseAreaDifference);
      } else {
        console.warn(
          "Invalid are percentage found in localStorage:",
          savedDiscount
        );
      }
    }
  }, []);

  // console.log("The workshop percentage: ", workdiscountPercentage);
  // console.log("geeting the area percentage: ", areaDifference)

  return (
    <Box
      sx={{
        borderRadius: "4px",
        backgroundColor: "#FFF",
        padding: "16px 16px",
      }}
    >
      <Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                color: "#101828",
              }}
            >
              {workshop?.workshop_name}
            </Typography>

            {areaDifference !== null && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 1,
                  flexWrap: "nowrap",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    textTransform: "none",
                    flexShrink: 1,
                    flexGrow: 1,
                    mr: 1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Savings on local hourly labour rate.
                </Typography>

                <Typography
                  sx={{
                    color: "#00C88C",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  }}
                >
                  <strong>Save</strong>&nbsp;
                  {`${areaDifference.toFixed(2)}%`}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        {/* The area and workshop percentage */}
        {workdiscountPercentage !== null && workdiscountPercentage > 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 1,
              flexWrap: "nowrap",
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "16px",
                textTransform: "none",
                flexShrink: 1,
                flexGrow: 1,
                mr: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Savings on parts pricing.
            </Typography>

            <Typography
              sx={{
                color: "#00C88C",
                fontSize: "16px",
                fontWeight: "500",
                textTransform: "capitalize",
              }}
            >
              <strong>Save</strong>&nbsp;
              {`${workdiscountPercentage.toFixed(2)}%`}
            </Typography>
          </Box>
        )}
      </Box>

      <Box pt={2}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            color: "#101828",
          }}
        >
          Physical Address
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#101828",
          }}
        >
          {workshop?.address}
        </Typography>
      </Box>
    </Box>
  );
};
