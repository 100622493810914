import { HeaderText } from "../../../components/common/HeaderText";
import CarIcon from "../../../assets/icons/carBlack.svg";
import servisorIcon from "../../../assets/servisorWhiteLogo.png";

import { useHistory } from "react-router-dom";
import LocationIcon from "../../../assets/icons/locationBlack.svg";
import serviceIcon from "../../../assets/icons/serviceBlack.svg";
import priceTag from "../../../assets/icons/priceTagIcon.svg";

import { SubHeaderText } from "../../../components/common/SubHeaderText";
import { Typography, Box, Container, Divider, Button } from "@mui/material";
import vehicleTag from "../../../assets/tags/bookingdetailsnew.svg";
import IssueHelpButton from "../../../components/common/IssueHelpButton";
import NextIcon from "../../../assets/icons/ChevronRightFilled.svg";

//To allow the below function to accept Props {typescript specific}
interface Props {
  emsEstimateData: any;
}

/**
 * the main component that displays vehicle and part information to the user
 * 
 * @param {string} Props.emsEstimateData- The data retrieved from EMS for the estimate
 *
 * @returns {JSX.Element} A JSX.Element representing the MainDetailsCard component.

 */
export const MainDetailsCard: React.FC<Props> = ({ emsEstimateData }) => {
  const vehicleDetails = JSON.parse(
    localStorage.getItem("vehicle-details") ?? ""
  );
  const locationDetails = JSON.parse(
    localStorage.getItem("temporary-rate-data") ?? ""
  );
  const history = useHistory();

  const hours = Math.floor(emsEstimateData?.total_labour_time);
  const mins = (emsEstimateData?.total_labour_time - hours) * 60;

  return (
    <>
      <HeaderText headerText="SERVICE DETAILS" headerImage={servisorIcon} />
      <Box
        sx={{
          display: "flex",
          alignSelf: { xs: "normal", lg: "center" },
          flexDirection: { xs: "column", lg: "column" },
          paddingTop: { xs: "50px", lg: "0" },
          paddingBottom: "100px",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            alignSelf: "center",
            width: { xs: "100%", lg: "70%" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <img src={vehicleTag} alt="/" />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <Button
              onClick={() => history.push("/confirmation")}
              sx={{
                padding: "8px",
                marginBottom: "19px",
                display: "flex",
                borderRadius: "10%",
                justifyContent: "center",
                backgroundColor: "#d1ddf8",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={NextIcon}
                  alt="/"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: "rotate(180deg)",
                  }}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#002064",
                  fontWeight: "500",
                  textTransform: "none",
                }}
              >
                Back
              </Typography>
            </Button>
          </Box>
        </Box>

        <SubHeaderText headerText="Your next car service includes the following:" />
        <Container disableGutters sx={{ width: { xs: "90%", lg: "70%" } }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              marginTop: { xs: "-10px", lg: "0" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#FFF",
                borderRadius: "4px",
                height: "56px",
                alignItems: "center",
                padding: "16px 16px",
              }}
            >
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box>
                  <img src={CarIcon} alt="/" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#101828",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    Vehicle
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Typography
                  sx={{
                    color: "#101828",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {vehicleDetails.vehicle_make} {vehicleDetails.desc}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#FFF",
                borderRadius: "4px",
                height: "56px",
                alignItems: "center",
                padding: "16px 16px",
              }}
            >
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box>
                  <img src={LocationIcon} alt="/" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#101828",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    Location
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Typography
                  sx={{
                    color: "#101828",
                    fontSize: "16px",
                    fontWeight: "500",
                    textAlign: "end",
                    textTransform: "capitalize",
                  }}
                >
                  {locationDetails.area}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#FFF",
                borderRadius: "4px",
                padding: "24px 16px",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box>
                  <img src={serviceIcon} alt="/" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#101828",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    Service Due
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ paddingY: "20px" }}>
                <Typography
                  sx={{
                    color: "#004FFA",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Inspection Service
                </Typography>
                <Typography
                  sx={{
                    color: "#101828",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  {emsEstimateData?.maintenance_details?.description}
                </Typography>
              </Box>

              <Divider />

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "space-between",
                    paddingY: "10px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#101828",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      Part
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#101828",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      Qty
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                {emsEstimateData?.items?.map((elem: any, index: number) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "space-between",
                        paddingY: "5px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color: "#101828",
                            fontSize: "16px",
                            fontWeight: "400",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {elem.price_desc.toLowerCase()}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#101828",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          {elem.quantity}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <Divider />

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                  paddingTop: "24px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "#101828",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    Labour
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#101828",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {`${hours}h ${mins.toFixed(0)}m`}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#FFF",
                borderRadius: "4px",
                height: "auto",
                padding: "24px 16px",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Box>
                    <img src={priceTag} alt="/" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#101828",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      Average Price
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      color: "#004FFA",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    {`R${emsEstimateData?.market_price.toFixed(2)}`}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Typography
                  sx={{
                    color: "#101828",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Amount is VAT inclusive.
                </Typography>

                <Typography
                  sx={{
                    color: "#101828",
                    fontSize: "15px",
                    fontWeight: "400",
                  }}
                >
                  This cost is an estimate, and is based off a number of
                  factors, including; Vehicle Particulars, Market related
                  Pricing for Parts and Service Location
                </Typography>

                <Typography
                  sx={{
                    color: "#101828",
                    fontSize: "15px",
                    fontWeight: "400",
                  }}
                >
                  Click Below to find a better deal from The Servisor Network!
                </Typography>
              </Box>
              <Box mt={3}>
                <IssueHelpButton />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};
