import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import bannerImage from "../../assets/bannerImage.svg";
import servisorWhite from "../../assets/servisorWhiteLogo.png";
import Lottie from "react-lottie";

export const ImageContainer = ({ animationData }: any) => {
  const dateNow = new Date();
  const defaultOptions = {
    loop: true,
    autoplay: true,

    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box
      sx={{
        backgroundColor: "#004FFA",
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box>
        <a
          href="https://www.servisor.co.za"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={servisorWhite}
            alt="/"
            style={{
              position: "absolute",
              top: "0",
              paddingTop: "40px",
              paddingLeft: "40px",
              // zIndex: 10,
            }}
          />
        </a>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", lg: "100%" },
          display: "flex",
          alignSelf: "center",
          marginTop: { xs: "0", lg: "0" },
          padding: "15%",
        }}
      >
        {/* <img
          src={bannerImage}
          alt="/"
          style={{ width: "228px", height: "375px" }}
        /> */}
        <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "0",
          left: "0",
          paddingBottom: "40px",
          paddingLeft: "40px",
          zIndex: 10,
        }}
      >
        <Typography sx={{ color: "#FFF", fontSize: "14px", fontWeight: "400" }}>
          © Servisor {dateNow.getFullYear()}
        </Typography>
      </Box>
    </Box>
  );
};
